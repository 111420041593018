window.addEventListener("DOMContentLoaded", (event) => {
  const container = document.querySelector(".edit");
  const containeract = document.querySelector(".form__password");

  container.onclick = function () {
    container.classList.toggle("act");
    containeract.classList.toggle("activeblock");
  };
});

document.getElementById("searchbutton").onclick = function () {
  document.getElementById("searchbox").classList.toggle("hidden");
};

document.getElementById("searchbuttons").onclick = function () {
  document.getElementById("searchboxs").classList.toggle("hiddens");
};
