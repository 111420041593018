window.onload = function () {
  const btns = document.querySelectorAll(".btn");
  const modalOverlay = document.querySelector(".modal-overlay");
  const modals = document.querySelectorAll(".modal");
  const modal = document.querySelector(".modal");
  const closeButtons = document.querySelectorAll(".modal__close");

  btns.forEach((el) => {
    el.addEventListener("click", (e) => {
      let path = e.currentTarget.getAttribute("data-path");

      modals.forEach((el) => {
        el.classList.remove("modal--visible");
      });
      document
        .querySelector(`[data-target="${path}"]`)
        .classList.add("modal--visible");
      modalOverlay.classList.add("modal-overlay--visible");
      document.body.style.setProperty("overflow", "hidden");
    });
  });

  modalOverlay.addEventListener("click", (e) => {
    if (e.target == modalOverlay) {
      modalOverlay.classList.remove("modal-overlay--visible");
      document.body.style.setProperty("overflow", "unset");
      modals.forEach((el) => {
        el.classList.remove("modal--visible");
      });
    }
  });

  closeButtons.forEach(function (item) {
    item.addEventListener("click", function (e) {
      modalOverlay.classList.remove("modal-overlay--visible");
    });
  });
};
